<app-drawer
  title="Фильтр"
  searchPlaceholder="Поиск по объявлениям"
  [opened]="isShowCategoriesDrawer()"
  (closeClick)="onChangeFunction()"
>
  <app-categories-filter
    [title]="title()"
    content
    (buttonPressed)="onChangeFunction()"
  ></app-categories-filter>
</app-drawer>
