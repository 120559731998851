import { Component, input } from '@angular/core';

import { EmptyListComponent } from '../empty-list/empty-list.component';
import { LoaderGlobalComponent } from '../loader-global/loader-global.component';

@Component({
  selector: 'app-data-wrapper',
  standalone: true,
  imports: [LoaderGlobalComponent, EmptyListComponent],
  templateUrl: './data-wrapper.component.html',
  styleUrl: './data-wrapper.component.scss',
})
export class DataWrapperComponent {
  isLoading = input.required<boolean>();
  isEmpty = input<boolean>(false);
  emptyDescription = input<string>('Список пуст');
}
