<app-drawer
  title="Категории"
  searchPlaceholder="Поиск по категориям"
  [opened]="isShowCategoriesDrawer()"
  (searchText)="onSearch($event)"
  (closeClick)="onChangeFunction()"
>
  <div content class="categories-list">
    @if (common().length > 0 || allotted().length > 0) {
      <div class="common-categories-block">
        @for (category of common(); track category) {
          <app-category-link
            [category]="category"
            width="46px"
            height="46px"
            class="category-link"
          />

          <!-- Блок для выравнивания категорий -->
          <div class="empty"></div>
        }
      </div>

      <div class="allotted-categories-block">
        @for (category of allotted(); track category) {
          <app-category-link
            [category]="category"
            [allottedCategory]="true"
            width="46px"
            height="46px"
          />
        }
      </div>
    } @else {
      <div class="empty-list">Категории не найдены</div>
    }
  </div>
</app-drawer>
