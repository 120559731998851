import { Component, input, output } from '@angular/core';

import { CategoryLinkComponent } from '@components/pages/home/category-link/category-link.component';
import { DrawerComponent } from '@components/common/drawer/drawer.component';
import { CategoriesFilterComponent } from '@components/pages/categories/categories-filter/categories-filter.component';

@Component({
  selector: 'app-categories-filter-drawer',
  standalone: true,
  imports: [CategoryLinkComponent, DrawerComponent, CategoriesFilterComponent],
  templateUrl: './categories-filter-drawer.component.html',
  styleUrl: './categories-filter-drawer.component.scss',
})
export class CategoriesFilterDrawerComponent {
  isShowCategoriesDrawer = input.required<boolean>();
  title = input.required<string>();
  onChange = output();

  onChangeFunction() {
    this.onChange.emit();
  }
}
