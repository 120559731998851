import { Component, computed, inject, input } from '@angular/core';
import { NgClass } from '@angular/common';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { map } from 'rxjs';
import { toSignal } from '@angular/core/rxjs-interop';

import { TMainPageCategory } from '@models/commonTypes';
import { SproutIconComponent } from '@components/ui/icons/sprout-icon.component';
import { RecyclingIconComponent } from '@components/ui/icons/recycling-icon.component';
import { ROUTE_CATEGORY } from '@settings/routes';
import { TAnnouncementType } from '@models/announcementTypes';

@Component({
  selector: 'app-category-link',
  standalone: true,
  imports: [SproutIconComponent, RecyclingIconComponent, NgClass, RouterLink],
  templateUrl: './category-link.component.html',
  styleUrl: './category-link.component.scss',
})
export class CategoryLinkComponent {
  category = input.required<TMainPageCategory>();
  allottedCategory = input<boolean>(false);
  width = input<string>('64px');
  height = input<string>('64px');

  route = inject(ActivatedRoute);

  categoryLink = computed(() => {
    const baseUrl = [ROUTE_CATEGORY, this.category().category_code];
    const cerealId = this.category().cereal_id;
    return cerealId ? [...baseUrl, cerealId] : baseUrl;
  });

  queryParams = toSignal(
    this.route.queryParams.pipe(
      map((params) => {
        const typeSell: TAnnouncementType =
          params['type_value'] === 'buy' ? 'buy' : 'sell';
        return { type_value: typeSell };
      }),
    ),
  );
}
