<a
  [routerLink]="categoryLink()"
  [queryParams]="queryParams()"
  [ngClass]="{
    'category-link': true,
    allotted: allottedCategory(),
  }"
>
  <span class="label">{{ category().name }}</span>

  @if (category().icon) {
    <img [src]="category().icon" alt="Icon" class="category-icon" />
  } @else {
    <div class="category-empty-icon"></div>
  }

  <!-- @if (!category().icon && category().category_code === "grain_waste") {
    <app-recycling-icon
      [width]="width()"
      [height]="height()"
    ></app-recycling-icon>
  }

  @if (!category().icon && category().category_code !== "grain_waste") {
    <app-sprout-icon [width]="width()" [height]="height()"></app-sprout-icon>
  } -->
</a>
