<div class="data-wrapper">
  @if (isLoading()) {
    <app-loader-global />
  }
  @if (!isLoading() && isEmpty()) {
    <app-empty-list [description]="emptyDescription()" />
  }
  @if (!isLoading() && !isEmpty()) {
    <ng-content />
  }
</div>
